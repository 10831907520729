import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { StaticImage, getImage } from "gatsby-plugin-image"

import ExternalLinkWithIcon from "../components/ExternalLinkWithIcon"
import ExternalLink from "../components/ExternalLink"

const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org/",
          "@type": "Person",
          "name": "Cary Stothart",
          "givenName": "Cary",
          "familyName": "Stothart",
          "honorificSuffix": "Ph.D.",
          "hasOccupation": "Research Psychologist",
          "knowsAbout": [
            "Software Development", 
            "Machine Learning", 
            "Research Design"
          ],
          "alumniOf": [
            "Florida State University",
            "University of Utah"
          ],
          "sameAs": [
            "https://scholar.google.com/citations?user=ebpys10AAAAJ", 
            "https://www.researchgate.net/profile/Cary-Stothart",
            "https://www.linkedin.com/in/cary-stothart-ph-d-0102402a",
            "https://github.com/cstothart"
          ]
        }
      `}
        </script>
      </Helmet>
      <div className="sm:flex sm:items-center sm:place-content-between sm:space-x-9 sm:mt-6">
        <div className="flex justify-center sm:block">
          <StaticImage
            className="w-1/3 sm:w-auto mt-6 md:mt-0"
            placeholder="blurred"
            src="../images/tank-picture-circle.png"
            alt=""
            width={375}
          />
        </div>
        <div className="space-y-6 sm:w-2/3 mt-6 sm:mt-0">
          <p>
            I am a research psychologist (Ph.D. in Cognitive Psychology) with
            experience in machine learning and software development (primarily
            web development).
          </p>
          <p>
            I received my Ph.D. in Cognitive Psychology at Florida State
            University under{" "}
            <ExternalLink href="https://psy.fsu.edu/faculty/charnessn/charness.dp.php">
              Dr. Neil Charness
            </ExternalLink>{" "}
            after earning a B.S. in Psychology at the University of Utah. I also
            received training at the University of Notre Dame as a postdoctoral
            research fellow under{" "}
            <ExternalLink href="https://psychology.nd.edu/faculty/james-r-brockmole/">
              Dr. James Brockmole
            </ExternalLink>
            . More recently, I earned{" "}
            <ExternalLink href="https://www.certmetrics.com/comptia/public/verification.aspx?code=5LG2QWLPHCEE1G9M">
              Security+ CE
            </ExternalLink>{" "}
            certification to further my software development skills.
          </p>
          <p>You can find me on:</p>
          <div className="grid gap-3 grid-cols-2 sm:flex sm:flex-wrap pt-3">
            {data.allSocialMediaJson.nodes.map(node => {
              const image = getImage(node.image)
              return (
                <ExternalLinkWithIcon
                  key={node.name}
                  label={node.name}
                  icon={image}
                  href={node.url}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allSocialMediaJson {
      nodes {
        image {
          childImageSharp {
            gatsbyImageData(height: 16, placeholder: BLURRED)
          }
        }
        name
        url
      }
    }
  }
`
