import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

type ExternalLinkType = {
  children: React.ReactNode
  href: string
}

const ExternalLink = ({ children, href }: ExternalLinkType) => (
  <OutboundLink
    className="underline hover:opacity-70"
    href={href}
    target="_blank"
    rel="noopener"
  >
    {children}
  </OutboundLink>
)

export default ExternalLink
