import * as React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

type ExternalLinkWithIconType = {
  label: string
  href: string
  icon: IGatsbyImageData
  extraStyle?: string
}

const ExternalLinkWithIcon = ({
  label,
  icon,
  href,
  extraStyle,
}: ExternalLinkWithIconType) => (
  <OutboundLink
    className={`rounded-lg shadow-sm border border-gray-200 bg-white md:hover:bg-gray-100 ${extraStyle}`}
    href={href}
    target="_blank"
  >
    <div className="flex space-x-2 m-2">
      <GatsbyImage image={icon} alt="" />
      <div className="text-xs font-semibold">{label}</div>
    </div>
  </OutboundLink>
)

export default ExternalLinkWithIcon
